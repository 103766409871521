import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setuserSlice: (state, action) => {   
      // console.log(action.payload,'action from redux')
      if (action.payload) {
        const item = state.find((pro) => pro.id === action.payload.id);

        if (!item) {
          state.push({
            ...action.payload,
          })
        } 
      }
     
    },
  },
});

export const {
  setuserSlice,
} = userSlice.actions;
export default userSlice.reducer;
