import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// import PPIF from '../assets/ppiflogo.jpg'
import logoImage from '../assets/hamrah.png'
import hdppif from '../assets/ppiflogo.png'



// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 100, height: 100, ...sx , display:'flex',flexDirection:'row'}}>
      
      <img src={logoImage} alt='logo'/>
      <img src={hdppif} alt = 'ppif logo' style={{height:55,width:100,alignSelf:'center'}} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
