import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const userAuthApi = createApi({
  reducerPath: 'userAuthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://familyp.firefly-techsolutions.com/web/api',
  }),
  tagTypes: ['Post','FC'],
  endpoints: builder => ({
    postLike:builder.mutation({
      query: (data) => {
        return {
          url: "create_like",
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
      invalidatesTags : ['Albums']
    }),

    deletePost:builder.mutation({
      query: (data) => {
        return {
          url: "delete_post",
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
      invalidatesTags : ['Post']
    }),

    updatePost:builder.mutation({
      query: (data) => {
        return {
          url: "update_post",
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
      invalidatesTags : ['Post']
    }),

    createPost:builder.mutation({
      query: (data) => ({
          url: "create_post",
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json",
          },
      }),
      invalidatesTags: ['Post']
    }),

    getallPosts: builder.query({
      query: () => ({
        url: 'get_all_posts',
        method: 'GET',
        headers : {
          "Content-type": "application/json",
        }
      }),
      providesTags : ['Post']
    }),

    
    // comment section Apis .........

    getallComments: builder.query({
      query: () => ({
        url: 'get_all_comments',
        method: 'GET',
        headers : {
          "Content-type": "application/json",
        }
      })
    }),

    createComment:builder.mutation({
      query: (data) => {
        return {
          url: "create_comment",
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
      invalidatesTags : ['Post']
    }),

    approveComment : builder.mutation({
      query: (data) => {
        return {
          url: "approve_comment",
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
      invalidatesTags : ['Post']
    }),

    deleteComment : builder.mutation({
      query: (data) => {
        return {
          url: "delete_comment",
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
      invalidatesTags : ['Post']
    }),

    updateComment : builder.mutation({
      query: (data) => {
        return {
          url: "update_comment",
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
      invalidatesTags : ['Post']
    }),


    addLike : builder.mutation({
      query: ({data, token}) => {
        return {
          url: "post/like/add",
          method: "POST",
          body: data,
          headers: {
            "authorization" : `Bearer ${token}`,
            "Content-type": "application/json",
          },
        };
      },
    }),
    
    updatePin : builder.mutation({
      query: (data) => {
        return {
          url: "update_pin",
          method: "POST",
          body: data,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
      invalidatesTags : ['Albums']
    }),
    
    getAllUsers: builder.query({// this api 
      query: () => ({
        url: 'get_all_users',
        method: 'GET',
        headers : {
          "Content-type": "application/json",
        }
      })
    }),
    
        createFC : builder.mutation({
          query: (data) => {
            return {
              url: "create_FC",
              method: "POST",
              body: data,
              headers: {
                "Content-type": "application/json",
              },
            };
          },
          invalidatesTags : ['FC']
        }),

        updateFC : builder.mutation({
          query: (data) => {
            return {
              url: "update_FC",
              method: "POST",
              body: data,
              headers: {
                "Content-type": "application/json",
              },
            };
          },
          invalidatesTags : ['FC']
        }),

        addCommentLike : builder.mutation({
          query: (data) => {
            return {
              url: "comment_like_dislike",
              method: "POST",
              body: data,
              headers: {
                "Content-type": "application/json",
              },
            };
          },
          invalidatesTags : ['Posts']
        }),
    
  }),
});

export const{
   usePostLikeMutation,
   useGetallPostsQuery, 
   useAddLikeMutation, 
   useCreatePostMutation,
   useCreateCommentMutation, 
   useGetallCommentsQuery ,
   useUpdatePinMutation,
   useGetAllUsersQuery,
   useDeletePostMutation,
   useUpdatePostMutation,
   useApproveCommentMutation,
   useDeleteCommentMutation,
   useUpdateCommentMutation,
   useCreateFCMutation,
   useUpdateFCMutation,
   useAddCommentLikeMutation
  }= userAuthApi;
