import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    // reactionAdded(state, action) {
    //   const { postId, userId } = action.payload;
    //   const existingPost = state.find((post) => post.id === postId);
    //   const index = existingPost.reactions.some((todo) => todo.userId === userId);
    //   if (index === false) {
    //     existingPost.reactions.push({
    //       userId,
    //       postId,
    //       like: Number(0) + Number(1),
    //     });
    //   } else if (index === true) {
    //     for (let i = 0; i < existingPost.reactions.length; i += 1) {
    //       if (existingPost.reactions[i].userId === userId) {
    //         existingPost.reactions.splice(i, 1);
    //       }
    //     }
    //   }
    // },
    changeCommentShow(state, action) {
      const existingPost = state.find((post) => post.id === action.payload);
      if (existingPost.showComments === true) {
        existingPost.showComments = false;
      } else {
        existingPost.showComments = true;
      }
    },
    // addNewComment(state, action) {
    //   const { postId } = action.payload;
    //   const existingPost = state.find((post) => post.id === postId);
    //   if (existingPost) {
    //     existingPost.comments.push(action.payload);
    //   }
    // },
    // incrementCommentReaction(state, action) {
    //   const { postId, commentId, userId } = action.payload;
    //   const existingPost = state.find((post) => post.id === postId);
    //   const reactedComment = existingPost.comments.find((comment) => comment.commentId === commentId);
    //   const index = reactedComment.commentReaction
    //     ? reactedComment.commentReaction.some((todo) => todo.userId === userId)
    //     : null;
    //   if (index === false) {
    //     reactedComment.commentReaction.push({
    //       userId,
    //       like: Number(0) + Number(1),
    //     });
    //   } else if (index === true) {
    //     for (let i = 0; i < reactedComment.commentReaction.length; i += 1) {
    //       if (reactedComment.commentReaction[i].userId === userId) {
    //         reactedComment.commentReaction.splice(i, 1);
    //       }
    //     }
    //   }
    // },
    // addCommentReply(state, action) {
    //   const { postId, userId, commentId } = action.payload;
    //   const existingPost = state.find((post) => post.id === postId);
    //   const reactedComment = existingPost.comments.find((comment) => comment.commentId === commentId);
    //   if (existingPost) {
    //     reactedComment.reply.push(action.payload);
    //   }
    // },
    // changeCommentReplyShow(state, action) {
    //   const { postId, commentId } = action.payload;
    //   const existingPost = state.find((post) => post.id === postId);
    //   const reactedComment = existingPost.comments.find((comment) => comment.commentId === commentId);

    //   if (reactedComment.showReply === true) {
    //     reactedComment.showReply = false;
    //   } else {
    //     reactedComment.showReply = true;
    //   }
    // },
    // incrementCommentReplyReaction(state, action) {
    //   const { postId, commentId, userId, replyId } = action.payload;
    //   const existingPost = state.find((post) => post.id === postId);
    //   const reactedComment = existingPost.comments.find((comment) => comment.commentId === commentId);
    //   const commentReply = reactedComment.reply.find((rep) => rep.replyId === replyId);
    //   const index = commentReply.commentReaction
    //     ? commentReply.commentReaction.some((todo) => todo.userId === userId)
    //     : null;

    //   if (index === false) {
    //     commentReply.commentReaction.push({
    //       userId,
    //       like: Number(0) + Number(1),
    //     });
    //   } else if (index === true) {
    //     for (let i = 0; i < commentReply.commentReaction.length; i += 1) {
    //       if (commentReply.commentReaction[i].userId === userId) {
    //         commentReply.commentReaction.splice(i, 1);
    //       }
    //     }
    //   }
    // },
    setPost: (state, action) => {
      if (action.payload) {
        const item = state.find((pro) => pro.id === action.payload.id);
        if (!item) {
          state.push({
            ...action.payload,
            showComments : false
          })
        } 
      }
    },
  },
});

// export const selectAllFeeds = (state) => state.feed.map((item) => item);
export const {
  reactionAdded,
  changeCommentShow,
  addNewComment,
  incrementCommentReaction,
  changeCommentReplyShow,
  addCommentReply,
  incrementCommentReplyReaction,
  setPost,
} = feedSlice.actions;
export default feedSlice.reducer;
