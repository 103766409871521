import { createSlice } from '@reduxjs/toolkit';
import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  facilitycenters: getIcon('ic_banking'),
};

const initialState = [
  // GENERAL

  // ----------------------------------------------------------------------
  {
    adminModules: [
      {
        items: [
          { id: '11', value: '11', label: 'dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
          // { value: '', label: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
          // { value: '', label: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
          // { value: '', label: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
          // { value: '', label: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        items: [
          // MANAGEMENT : USER
          // { id: '2', value: '2', label: 'modules', path: PATH_DASHBOARD.modules, icon: ICONS.analytics },
          // {
          //   id: '12',
          //   value: '12',
          //   label: 'Access Control',
          //   path: PATH_DASHBOARD.modules.root,
          //   icon: ICONS.facilitycenters,
          //   children: [
          //     { id: '13', value: '13', label: 'assign modules', path: PATH_DASHBOARD.modules.assignModules },
          //     { id: '14', value: '14', label: 'Manage modules', path: PATH_DASHBOARD.modules.manageModules },
          //     { id: '15', value: '15', label: 'Assign Roles', path: PATH_DASHBOARD.modules.assignRoles },
          //     { id: '16', value: '16', label: 'Manage Roles', path: PATH_DASHBOARD.modules.manageRoles },
          //     { id: '17', value: '17', label: 'Manage Users', path: PATH_DASHBOARD.modules.manageUsers },
          //   ],
          // },
          // {
          //   id: '3',
          //   value: '3', label: 'general',
          //   path: PATH_DASHBOARD.user.root,
          //   icon: ICONS.user,
          //   children: [
          //     { id: '4', value: '4', label: 'profile', path: PATH_DASHBOARD.user.profile },
          //     { value: '', label: 'cards', path: PATH_DASHBOARD.user.cards },
          //     { id: '5', value: '5', label: 'edit profile', path: PATH_DASHBOARD.user.editById },
          //     { value: '', label: 'account', path: PATH_DASHBOARD.user.account },
          //   ],
          // },

          // MANAGEMENT : E-COMMERCE
          // {
          //   value: '6', label: 'e-commerce',
          //   path: PATH_DASHBOARD.eCommerce.root,
          //   icon: ICONS.cart,
          //   children: [
          //     { value: '7', label: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          //     { value: '8', label: 'product', path: PATH_DASHBOARD.eCommerce.productById },
          //     { value: '9', label: 'list', path: PATH_DASHBOARD.eCommerce.list },
          //     { value: '12', label: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
          //     { value: '11', label: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
          //     { value: '13', label: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
          //     { value: '14', label: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
          //   ],
          // },

          // MANAGEMENT : BLOG
          {
            id: '18',
            value: '18',
            label: 'Feed',
            path: PATH_DASHBOARD.blog.root,
            icon: ICONS.blog,
            children: [
              { id: '19', value: '19', label: 'posts', path: PATH_DASHBOARD.blog.posts },
              // { value: '', label: 'post', path: PATH_DASHBOARD.blog.postById },
              // { id: '21', value: '21', label: 'new post', path: PATH_DASHBOARD.blog.newPost },
            ],
          },
        ],
      },

      // APP
      // ----------------------------------------------------------------------
      {
        items: [
          // {
          //   value: '', label: 'mail',
          //   path: PATH_DASHBOARD.mail.root,
          //   icon: ICONS.mail,
          //   info: (
          //     <Label variant="outlined" color="error">
          //       +32
          //     </Label>
          //   ),
          // },
          // { id: '22', value: '22', label: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
          { id: '21', value: '21', label: 'Visitors', path: PATH_DASHBOARD.visitors, icon: ICONS.calendar },
          {
            id: '22',
            value: '22',
            label: 'facility centers',
            path: PATH_DASHBOARD.facilitycenters.root,
            icon: ICONS.facilitycenters,
            children: [
              { id: '23', value: '23', label: 'Facility Center list', path: PATH_DASHBOARD.facilitycenters.list },
              { id: '24', value: '24', label: 'create facility center', path: PATH_DASHBOARD.user.newUser },
            ],
          },
          {
            id: '25',
            value: '25',
            label: 'Reports',
            path: PATH_DASHBOARD.reports.root,
            icon: ICONS.calendar,
            children: [
              { id: '25', value: '25', label: 'Service Provider Report', path: PATH_DASHBOARD.reports.VisitorsReports },
              { id: '26', value: '26', label: 'Users Report', path: PATH_DASHBOARD.reports.UsersReports },
              { id: '27', value: '27', label: 'Self Checkout Report', path: PATH_DASHBOARD.reports.SelfCheckout },
              { id: '28', value: '28', label: 'MCWheel Assessment Report', path: PATH_DASHBOARD.reports.MCWheel },
            ],
          },

          // {
          //   value: '', label: 'kanban',
          //   path: PATH_DASHBOARD.kanban,
          //   icon: ICONS.kanban,
          // },
        ],
      },
    ],
  },
  {
    facilityCenterModules: [
      {
        items: [
          {
            id: '26',
            value: '26',
            label: 'Visitors List',
            path: PATH_DASHBOARD.facilitCenter.visitorsList,
            icon: ICONS.dashboard,
          },
        ],
      },
    ],
  },
];

const moduleSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {},
});

export const allModules = (state) => state.modules;
export default moduleSlice.reducer;
