// ----------------------------------------------------------------------


function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/dashboard';
const FC_DASHBOARD = '/FacilityCenterLand';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, 'login'),
  loginUnprotected: path(ROOTS_AUTH, 'login-unprotected'),
  register: path(ROOTS_AUTH, 'register'),
  registerUnprotected: path(ROOTS_AUTH, 'register-unprotected'),
  resetPassword: path(ROOTS_AUTH, 'reset-password'),
  verify: path(ROOTS_AUTH, 'verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  facilitycenters : {
    root : path(ROOTS_DASHBOARD, '/facilitycenters'),
    list: path(ROOTS_DASHBOARD, '/facilitycenters/list'),
    editFC: path(ROOTS_DASHBOARD, '/facilitycenters/reece-chung/editFC'),

  },  
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  visitors: path(ROOTS_DASHBOARD, '/visitors'),
  reports: {
    root : path(ROOTS_DASHBOARD, '/reports'),
    VisitorsReports : path(ROOTS_DASHBOARD, '/visitors-reports'),
    UsersReports : path(ROOTS_DASHBOARD, '/users-reports'),
    SelfCheckout : path(ROOTS_DASHBOARD, '/selfcheckout-reports'),
    MCWheel : path(ROOTS_DASHBOARD, '/MCWheel-Assessment-reports')
    
  },
  modules:{
    root: path(ROOTS_DASHBOARD, '/modules'),
    assignModules :  path(ROOTS_DASHBOARD, '/modules/assignModules'),
    manageModules: path(ROOTS_DASHBOARD, '/modules/manageModules'),
    assignRoles: path(ROOTS_DASHBOARD, '/modules/assignRoles'),
    manageRoles: path(ROOTS_DASHBOARD, '/modules/manageRoles'),
    manageUsers: path(ROOTS_DASHBOARD, '/modules/manageUsers'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    editpin: path(ROOTS_DASHBOARD, '/user/editpin')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  },
  facilitCenter : {
      fc: path(FC_DASHBOARD, '/fc'),
      visitorsList: path(FC_DASHBOARD, '/VisitorsReports'),
  }

};


export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
