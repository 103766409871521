import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import modulesReducer from './slices/moduleSlice';
import loginSlice from './slices/loginSlice';
import feedSlice from './slices/feedSlice';
import usersSlice from './slices/usersSlice';

// APis 

import { userAuthApi } from '../service/userAuthAPIs'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  [userAuthApi.reducerPath]: userAuthApi.reducer,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  modules : modulesReducer,
  auth : loginSlice,
  feed : feedSlice,
  user : usersSlice,
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
