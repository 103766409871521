import { createSlice } from '@reduxjs/toolkit';

const initialState = []

const loginSlice = createSlice({
    name : 'auth',
    initialState,
    reducers: {}
  })

  export default loginSlice.reducer;