import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN, FC_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
// import UsersReports from '../pages/dashboard/UsersReports';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks 
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};
const FcLoadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks 
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/FacilityCenterLand')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    {
      path: '/',
      element :  <GuestGuard>
      <Login />
    </GuestGuard>,
      children: [
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path : 'FacilityCenterLand',
      element :(
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={FC_AFTER_LOGIN} replace />, index: true },
        { path: 'fc', element: <FacilitCenterLand /> },
        { path: 'visitorsList', element: <VisitorsList /> },

      ]
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        // {
        //   path: 'e-commerce',
        //   children: [
        //     { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
        //     { path: 'shop', element: <EcommerceShop /> },
        //     { path: 'product/:name', element: <EcommerceProductDetails /> },
        //     { path: 'list', element: <EcommerceProductList /> },
        //     { path: 'product/new', element: <EcommerceProductCreate /> },
        //     { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
        //     { path: 'checkout', element: <EcommerceCheckout /> },
        //     { path: 'invoice', element: <EcommerceInvoice /> },
        //   ],
        // },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            // { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <CreateFCScreen /> },
            { path: ':name/edit', element: <CreateFCScreen /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'editPin', element: <EditPinScreen /> },
          ],
        },
        {
          path: 'modules',
          children: [
            { path: 'assignModules', element: <Modules /> },
            { path: 'manageModules', element: <ManageModules/> },
            { path: 'assignRoles', element: <AssignRoles/> },
            { path: 'manageRoles', element: <ManageRoles/> },
            { path: 'manageUsers', element: <ManageUsers/> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        // {
        //   path: 'chat',
        //   children: [
        //     { element: <Chat />, index: true },
        //     { path: 'new', element: <Chat /> },
        //     { path: ':conversationKey', element: <Chat /> },
        //   ],
        // },
        { path: 'calender', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'visitors', element: <Visitors /> },
        { path: 'visitors-reports', element: <VisitorsReports /> },
        { path: 'users-reports', element: <UsersReports /> },
        {path: 'selfcheckout-reports',element:<SelfCheckoukReports /> },
        {path: 'MCWheel-Assessment-reports',element:<MCWheelAssessmentReports /> },

        {
          path: 'facilitycenters',
          children: [
            { element: <UserList />, index: true },
            { element: <UserList />, index: true },
            { path: 'list', element: <UserList /> },
            { path: ':name/editFC', element: <EditFC /> },
          ],
        }
      ],
    },

  
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const CreateFCScreen = Loadable(lazy(() => import('../pages/dashboard/CreateFCScreen')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
const Visitors = Loadable(lazy(() => import('../pages/dashboard/Visitors')));
const Modules = Loadable(lazy(() => import('../pages/dashboard/Modules')));
const EditFC = Loadable(lazy(() => import('../pages/dashboard/EditFCScreen')));
const ManageModules = Loadable(lazy(() => import('../pages/dashboard/ManageModules')));
const AssignRoles = Loadable(lazy(() => import('../pages/dashboard/AssignRoles')));
const ManageRoles = Loadable(lazy(() => import('../pages/dashboard/ManageRoles')));
const ManageUsers = Loadable(lazy(() => import('../pages/dashboard/ManageUsers')));
const VisitorsReports = Loadable(lazy(() => import('../pages/dashboard/VisitorsReports')));
const UsersReports = Loadable(lazy(() => import('../pages/dashboard/UsersReports')));
const SelfCheckoukReports = Loadable(lazy(()=> import ('../pages/dashboard/SelfCheckoutReport')));
const MCWheelAssessmentReports = Loadable(lazy(()=> import("../pages/dashboard/MCWheelAssessmentReport")));

const EditPinScreen = Loadable(lazy(() => import('../pages/dashboard/EditPinScreen')));
// Facility Center 
const FacilitCenterLand = FcLoadable(lazy(() => import('../pages/dashboard/FacilityCenterLand')))
const VisitorsList = FcLoadable(lazy(() => import('../pages/dashboard/VisitorsList')))

// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
